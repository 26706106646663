import React, { useEffect, useState } from 'react'
import Header from '../CustomMUI/Header'
import { Card, CardContent, CircularProgress } from '@material-ui/core'
import Styles from './MatchToss.module.css';
import TeamCard from '../CustomMUI/TeamCard';
import { txt } from "../../common/context";
import { PrimaryButton } from '../CustomMUI/CustomButtons';
import { BASE_URL, sendHttpRequest } from '../../common/Common';
import { toast } from 'react-toastify';
import TeamInfoBigCard from '../TeamInfoBigCard/TeamInfoBigCard';

export default function MatchToss({ setIsShow, selectedMatch }) {

  const [toss, setToss] = useState({
    wonTossTeam: null,
    batFirst: null,
    battingTeam: null,
    bowlingTeam: null,
    matchId: selectedMatch?._id
  });

  const [isWaitForRes, setIsWaitForRes] = useState(false);

  const updateTossWonDetails = async () => {
    setIsWaitForRes(true)
    try {
      const res = await sendHttpRequest(
        "POST",
        `${BASE_URL}/api/match/toss`, null, JSON.stringify(toss)
      );

      if (res.data) {
        setIsShow(3)
        setIsWaitForRes(false)
      }
    } catch (error) {
      setIsWaitForRes(false)
      toast.error(error.response.data.message);
    }
  }

  const handleWonToss = (chooseTo, team) => {
    if (team?._id === selectedMatch?.teamA?._id) {
      setToss({
        wonTossTeam: team?._id,
        batFirst: chooseTo === "Bat" ? true : false,
        battingTeam: chooseTo === "Bat" ? selectedMatch?.teamA?._id : selectedMatch?.teamB?._id,
        bowlingTeam: chooseTo === "Bat" ? selectedMatch?.teamB?._id : selectedMatch?.teamA?._id,
        matchId: selectedMatch?._id
      })
    } else {
      setToss({
        wonTossTeam: team?._id,
        batFirst: chooseTo === "Bat" ? true : false,
        battingTeam: chooseTo === "Bat" ? selectedMatch?.teamB?._id : selectedMatch?.teamA?._id,
        bowlingTeam: chooseTo === "Bat" ? selectedMatch?.teamA?._id : selectedMatch?.teamB?._id,
        matchId: selectedMatch?._id
      })
    }
  }

  return (
    <>
      <Header
        title={"Match Toss"}
        isModal={true}
        closeModal={() => setIsShow(1)}
      />

      <div className="form-container" style={{ paddingTop: 0, marginTop: '56px', position: 'relative', minHeight: 'calc(100vh - 112px)' }}>
        <Card className="card card-padding" elevation={0} style={{ padding: '0px' }}>
          <CardContent style={{ padding: 0, display: 'flex', flexDirection: 'column', gap: '15px', minHeight: '97%' }}>

            <p className='text-center'>Select the team that won the toss to proceed</p>

            <div className={Styles.AdContainer}>
              <p>Advertisement Space</p>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <TeamInfoBigCard
                teamInfo={selectedMatch?.teamA}
                setWonTossTeam={(value) => {
                  handleWonToss(value, selectedMatch?.teamA)
                }}
              />
              <TeamInfoBigCard
                teamInfo={selectedMatch?.teamB}
                setWonTossTeam={(value) => {
                  handleWonToss(value, selectedMatch?.teamB)
                }}
              />
            </div>

            <div style={{ marginTop: 'auto' }}>
              <PrimaryButton onClick={updateTossWonDetails} disabled={!toss?.wonTossTeam || toss?.batFirst === null}>
                {
                  isWaitForRes ?
                    <CircularProgress style={{ margin: 'auto' }} />
                    :
                    txt.continue_to_scoresheet
                }
              </PrimaryButton>
            </div>

          </CardContent>
        </Card>
      </div>
    </>
  )
}
