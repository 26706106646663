import React, { useState } from 'react'
import './TeamInfoBigCard.css'
import TeamImg from '../../assets/images/team/team.jpg'
import BatBallIcon from "../../assets/images/svg/batBallWithGrayBg.svg";
import OptionsBottonSheet from '../CustomSelectField/OptionsBottonSheet'

const TeamInfoBigCard = ({ setWonTossTeam, teamInfo }) => {
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const options = ["Bat", "Field"];

  return (
    <>
      <div onClick={() => setShowOptionsMenu(true)} className='TeamInfoBigCard'>
        <img src={TeamImg} className='teamLogo' alt="" />
        <div className='teamInfoContainer'>
          <p className='f1520400'>{teamInfo?.name}</p>
          <p className='f1113400'>{teamInfo?.owner?.firstName}</p>
        </div>
      </div>

      <OptionsBottonSheet
        errorMsg={`Please select what this team should start with`}
        isOpen={showOptionsMenu}
        onDismiss={() => setShowOptionsMenu(false)}
        title={'Choose to'} description={'Select what this team should start with'} providedIcon={BatBallIcon}
        options={options} setValue={setWonTossTeam}
      />
    </>
  )
}

export default TeamInfoBigCard