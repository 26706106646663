import { Box } from "@material-ui/core";
import React from "react";
import "../../styles/site.css";
import Header from "../CustomMUI/Header";

const More = () => {
  return (
    <div className="pageMainContainer">
        <Header title={'More'} />
    </div>
  );
};
export default More;
