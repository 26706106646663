import React, { useEffect, useState } from 'react'
import { txt } from '../../common/context'
import Header from '../CustomMUI/Header'
import ProfilPlacholder from "../../assets/images/svg/profil_placeholder.svg";
import CameroImg from "../../assets/images/svg/camero.svg";
import CustomTextField from '../CustomMUI/CustomTextField';
import { CircularProgress, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { PrimaryButton } from '../CustomMUI/CustomButtons';
import BottomSheet from '../CustomMUI/BottomSheet';
import RedBallImg from "../../assets/images/svg/redBall.svg";
import { battingStyleArr, bowlingStyleArr, playingRoleArr } from '../../constants/data';
import { useAuth } from '../../context/AuthContext';
import { BASE_URL, isEmail, sendHttpRequest, uploadToS3 } from '../../common/Common';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function EditMyProfile() {
  const { userInfo, updateUserInfo } = useAuth();
  const [profilePic, setProfilePic] = useState(userInfo?.profilePhoto || null);
  const [firstName, setFirstName] = useState(userInfo?.firstName || '');
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState(userInfo?.lastName || '');
  const [lastNameError, setLastNameError] = useState("");
  const [location, setLocation] = useState(userInfo?.location || '');
  const [birthDate, setBirthDate] = useState(userInfo?.birthDate || '');
  const [email, setEmail] = useState(userInfo?.email || '');
  const [playingRole, setPlayingRole] = useState(userInfo?.playingRole || '');
  const [battingStyle, setBattingStyle] = useState(userInfo?.battingStyle || '');
  const [bowlingStyle, setBowlingStyle] = useState(userInfo?.bowlingStyle || '');
  const [gender, setGender] = useState(userInfo?.gender || '');
  const history = useHistory();
  const [errors, setErrors] = useState({
    firstnameError: "",
    lastnameError: "",
    emailError: "",
    locationError: "",
    birthDateError: "",
    playingRoleError: "",
    battingStyleError: "",
    bowlingStyleError: "",
    genderError: "",
  });

  const [showBottomSheet, setShowBottomSheet] = useState({
    [txt.Playing_Role]: {
      radioGroupName: txt.Playing_Role,
      headerTitle: txt.Playing_Role,
      headerDesc: 'Select the playing role.',
      radioDataList: playingRoleArr,
      bodyTypeRadio: true,
      selectedRadioValue: playingRole,
      open: true,
    },
    [txt.Batting_Style]: {
      radioGroupName: txt.Batting_Style,
      headerTitle: txt.Batting_Style,
      headerDesc: 'Select the batting style.',
      radioDataList: battingStyleArr,
      bodyTypeRadio: true,
      selectedRadioValue: '',
      open: true,
    },
    [txt.Bowling_Style]: {
      radioGroupName: txt.Bowling_Style,
      headerTitle: txt.Bowling_Style,
      headerDesc: 'Select the bowling style.',
      radioDataList: bowlingStyleArr,
      bodyTypeRadio: true,
      selectedRadioValue: '',
      open: true,
    },
  });

  const [showBottomSheetFor, setShowBottomSheetFor] = useState();

  const handleProfilePicChange = async (e) => {
    const file = e.target.files[0];
    let imgUrl = await uploadToS3(file);
    setProfilePic(imgUrl);
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setProfilePic(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

  const handleUpdateUserInfo = () => {

    const newErrors = {};

    let valid = true;

    if (!firstName) {
      newErrors.firstnameError = txt.please_enter_first_name;
      valid = false;
    }

    if (!lastName) {
      newErrors.lastnameError = txt.please_enter_last_name;
      valid = false;
    }

    if (!isEmail(email)) {
      newErrors.emailError = txt.please_enter_valid_email_address;
      valid = false;
    }

    if (!location) {
      newErrors.locationError = txt.please_enter_location;
      valid = false;
    }

    if (!birthDate) {
      newErrors.birthDateError = txt.please_enter_date_of_birth;
      valid = false;
    }

    if (!playingRole) {
      newErrors.playingRoleError = txt.Please_select_playing_role;
      valid = false;
    }

    if (!battingStyle) {
      newErrors.battingStyleError = txt.Please_select_batting_style;
      valid = false;
    }

    if (!bowlingStyle) {
      newErrors.bowlingStyleError = txt.Please_select_bowling_style;
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) {
      return;
    }

    const data = {
      profilePhoto: profilePic,
      battingStyle: battingStyle,
      bowlingStyle: bowlingStyle,
      email: email,
      firstName: firstName,
      lastName: lastName,
      location: location,
      birthDate: birthDate,
      playingRole: playingRole,
      battingStyle: battingStyle,
      bowlingStyle: bowlingStyle,
      gender: gender,
    };

    sendHttpRequest("PUT", BASE_URL + "/api/player/" + localStorage.getItem("loggedInUserId"), null, JSON.stringify(data)).then((res) => {

      const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));

      if (res.data.data) {
        const updatedData = {
          ...currentUserInfo,
          profilePhoto: res.data?.data?.profilePhoto,
          battingStyle: res.data?.data?.battingStyle,
          bowlingStyle: res.data?.data?.bowlingStyle,
          email: res.data?.data?.email,
          firstName: res.data?.data?.firstName,
          lastName: res.data?.data?.lastName,
          location: res.data?.data?.location,
          birthDate: res.data?.data?.birthDate,
          playingRole: res.data?.data?.playingRole,
          gender: res.data?.data?.gender,
        };

        updateUserInfo(updatedData);
        toast.success("Profile Details Updated Successfully");
        history.goBack()
      }

    }).catch((error) => {
      toast.error(error.response.data.message)
    });
  }

  return (
    <div className='pageMainContainer'>
      <Header title={txt.Edit_My_Profile} />

      <div className="profile-pic-container" style={{ margin: '20px auto' }}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="profile-pic-input"

          type="file"
          onChange={handleProfilePicChange}
        />
        <label htmlFor="profile-pic-input">
          <img
            src={profilePic || ProfilPlacholder}
            alt="Profile Placeholder"
            className="profile-pic"
            style={{ cursor: "pointer" }}
          />
          <img
            src={CameroImg}
            alt="Camera Icon"
            className="camera-icon"
          />
        </label>
      </div>

      <div style={{ flex: '1', background: 'var(--card-1-bg)', padding: '10px 20px 20px 20px' }}>
        <div className="flex-container">
          <div className="flex-item">
            <CustomTextField
              label={txt.first_name}
              type="text"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setFirstNameError("");
              }}
              style={{ backgroundColor: "var(--card-1-bg)" }}
            />
            {errors.firstnameError && (
              <p className="error-message">{errors.firstnameError}</p>
            )}
          </div>

          <div className="flex-item">
            <CustomTextField
              label={txt.last_name}
              type="text"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setLastNameError("");
              }}
              style={{ backgroundColor: "var(--card-1-bg)" }}
            />
            {errors.lastnameError && (
              <p className="error-message">{errors.lastnameError}</p>
            )}
          </div>
        </div>


        <div style={{ display: 'block' }}>
          <CustomTextField
            label={txt.location}
            type="text"
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
            }}
            style={{ backgroundColor: "var(--card-1-bg)" }}
          />
          {errors.locationError && (
            <p className="error-message">{errors.locationError}</p>
          )}
        </div>

        <div style={{ display: 'block' }}>
          <CustomTextField
            label={txt.Date_of_Birth}
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={birthDate}
            onChange={(e) => {
              setBirthDate(e.target.value);
            }}
            style={{ backgroundColor: "var(--card-1-bg)" }}
          />
          {errors.birthDateError && (
            <p className="error-message">{errors.birthDateError}</p>
          )}
        </div>

        <div style={{ display: 'block' }}>
          <CustomTextField
            label={txt.email_address}
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            style={{ backgroundColor: "var(--card-1-bg)" }}
          />
          {errors.emailError && (
            <p className="error-message">{errors.emailError}</p>
          )}
        </div>


        <div style={{ display: 'block' }}>
          <CustomTextField
            label={txt.Playing_Role}
            type="text"
            value={playingRole || ''}
            readOnly={true}
            onClick={() => {
              setShowBottomSheetFor(txt.Playing_Role);
            }}
            style={{ backgroundColor: "var(--card-1-bg)" }}
          />
          {errors.playingRoleError && (
            <p className="error-message">{errors.playingRoleError}</p>
          )}
        </div>

        <div style={{ display: 'block' }}>
          <CustomTextField
            label={txt.Batting_Style}
            type="text"
            value={battingStyle || ''}
            readOnly={true}
            onClick={() => {
              setShowBottomSheetFor(txt.Batting_Style);
            }}
            style={{ backgroundColor: "var(--card-1-bg)" }}
          />
          {errors.battingStyleError && (
            <p className="error-message">{errors.battingStyleError}</p>
          )}
        </div>

        <div style={{ display: 'block' }}>
          <CustomTextField
            label={txt.Bowling_Style}
            type="text"
            value={bowlingStyle || ''}
            readOnly={true}
            onClick={() => {
              setShowBottomSheetFor(txt.Bowling_Style);
            }}
            style={{ backgroundColor: "var(--card-1-bg)" }}
          />
          {errors.bowlingStyleError && (
            <p className="error-message">{errors.bowlingStyleError}</p>
          )}
        </div>

        <RadioGroup
          defaultValue={gender || txt.Male}
          name={txt.Gender}
          style={{ flexDirection: 'row' }}
          onChange={(e) => {
            setGender(e.target.value)
          }}
        >
          <FormControlLabel value={txt.Male} control={<Radio />} label={txt.Male} />
          <FormControlLabel value={txt.Female} control={<Radio />} label={txt.Female} />
          <FormControlLabel value={txt.Prefer_Not_To_Say} control={<Radio />} label={txt.Prefer_Not_To_Say} />
        </RadioGroup>

        <div style={{ marginTop: '10px' }}>
          <PrimaryButton onClick={handleUpdateUserInfo}>
            {/* <CircularProgress /> */}
            {txt.Update}
          </PrimaryButton>
        </div>
      </div>

      {

        <BottomSheet
          open={showBottomSheet[showBottomSheetFor]?.open}
          hide={() => setShowBottomSheetFor('')}
          headerImg={RedBallImg}
          headerTitle={showBottomSheet[showBottomSheetFor]?.headerTitle}
          headerDesc={showBottomSheet[showBottomSheetFor]?.headerDesc}
          bodyTypeRadio={showBottomSheet[showBottomSheetFor]?.bodyTypeRadio}
          radioGroupName={showBottomSheet[showBottomSheetFor]?.radioGroupName}
          radioDataList={showBottomSheet[showBottomSheetFor]?.radioDataList}
          selectedRadioValue={() => {
            switch (showBottomSheetFor) {
              case txt.Playing_Role:
                return playingRole;
              case txt.Batting_Style:
                return battingStyle;
              case txt.Bowling_Style:
                return bowlingStyle;
            }
          }}
          onChangeSelectValue={(selectedValue) => {
            switch (showBottomSheetFor) {
              case txt.Playing_Role: setPlayingRole(selectedValue);
                break;
              case txt.Batting_Style: setBattingStyle(selectedValue);
                break;
              case txt.Bowling_Style: setBowlingStyle(selectedValue);
                break;
            }
            setShowBottomSheetFor('');
          }}
        />
      }
    </div>
  )
}

export default EditMyProfile