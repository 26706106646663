import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import { Button, Typography } from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";
import { KeyboardArrowLeft } from "@material-ui/icons";
import Avatar from "@material-ui/core/Avatar";
import AppLogoIcon from "../../assets/images/FieldR_Logo_Blue.png";
import ManuIcon from "../../assets/images/svg/manu.svg";
import MessageIcon from "../../assets/images/svg/message.svg";
import NotificationIcon from "../../assets/images/svg/notification.svg";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext
import ProfilPlacholder from "../../assets/images/svg/profil_placeholder.svg";
import "../Player/player.css";
import { CustomSmallButton } from "../CustomMUI/CustomSmallButton";
import PlayerIcon from "../../assets/images/svg/player.svg";
import TeamIcon from "../../assets/images/svg/team.svg";
import MatchIcon from "../../assets/images/svg/match.svg";
import TournamentIcon from "../../assets/images/svg/tournament.svg";
import { txt } from "../../common/context";
import { PrimaryButton } from "../CustomMUI/CustomButtons";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100%',
  },
  drawerPaper: {
    width: '100%',
  },
  headerDiv: {
    width: "100%",
    alignItems: 'center',
    display: "flex",
    justifyContent: "space-between",
  },
  logoImg: {
    height: "25px",
    marginLeft: "8px",
  },
}));

export default function PersistentDrawerRight({ title, farLeft, hideMenu }) {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { logout, userInfo } = useAuth();


  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <div style={{ display: 'flex' }}>
      <AppBar
        style={{ backgroundColor: "var(--primary-color-white)", boxShadow: 'none' }}
        position="fixed"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            style={{ display: 'flex', alignItems: 'center' }} onClick={() => setOpen(true)}
          >
            <img src={ManuIcon} alt="Menu Icon" />
          </IconButton>
          {farLeft && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => history.goBack()}
            >
              <KeyboardArrowLeft />
            </IconButton>
          )}
          <div className={classes.headerDiv}>
            <img src={AppLogoIcon} alt="App Logo" className={classes.logoImg} />
            {/* Uncomment if needed */}
            {/* <div>
              <IconButton edge="end" color="inherit">
                <img src={MessageIcon} alt="Messages Icon" />
              </IconButton>
              <IconButton edge="end" color="inherit">
                <img src={NotificationIcon} alt="Notifications Icon" />
              </IconButton>
            </div> */}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="p20" style={{ background: '#fff', width: '300px', height: '100%' }}>

          <div onClick={() => history.push("/my-profile")} className="flex g20">
            <img
              src={userInfo?.profilePhoto || ProfilPlacholder}
              alt="Profile Placeholder"
              className="profile-pic"
              style={{ cursor: "pointer" }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <p className="f1823400">{userInfo?.firstName} {userInfo?.lastName}</p>
              <p className="f1520400">+{userInfo?.contactNo}</p>
              <p className="f1113400">{userInfo?.email}</p>
              <CustomSmallButton name={'Free User'} type={'btn-gray'} />
            </div>
          </div>

          <Divider className="my-15" style={{ background: 'var(--netural-color-900)', height: '2px' }} />

          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div className="g20" style={{ display: 'flex', alignItems: 'center', }} onClick={() => history.push("/createPlayer")}>
              <img src={PlayerIcon} />
              <p className="f1520600">{txt.Create_a_New_Player}</p>
            </div>
            <div className="g20" style={{ display: 'flex', alignItems: 'center' }} onClick={() => history.push('/create-team')}>
              <img src={TeamIcon} />
              <p className="f1520600">{txt.Create_New_Team}</p>
            </div>
            <div className="g20" style={{ display: 'flex', alignItems: 'center' }} onClick={() => history.push('/createMatch')}>
              <img src={MatchIcon} />
              <p className="f1520600">{txt.Create_New_Match}</p>
            </div>
            <div className="g20" style={{ display: 'flex', alignItems: 'center' }} onClick={() => history.push('/tournament/create')}>
              <img src={TournamentIcon} />
              <p className="f1520600">{txt.Create_New_Tournament}</p>
            </div>
          </div>

          <Divider className="my-15" style={{ background: 'var(--netural-color-900)', height: '2px' }} />

          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div className="g20" style={{ display: 'flex', alignItems: 'center' }} onClick={() => history.push("/createPlayer")}>
              <img src={PlayerIcon} />
              <p className="f1520600">{txt.Leaderboards}</p>
            </div>
            <div className="g20" style={{ display: 'flex', alignItems: 'center' }} onClick={() => history.push('/create-team')}>
              <img src={TeamIcon} />
              <p className="f1520600">{txt.Payments}</p>
            </div>
            <div className="g20" style={{ display: 'flex', alignItems: 'center' }} onClick={() => history.push('/createMatch')}>
              <img src={MatchIcon} />
              <p className="f1520600">{txt.ShareTheApp}</p>
            </div>
            <div className="g20" style={{ display: 'flex', alignItems: 'center' }} onClick={() => history.push('/tournament/create')}>
              <img src={TournamentIcon} />
              <p className="f1520600">{txt.Settings}</p>
            </div>
          </div>

          <Divider className="my-15" style={{ background: 'var(--netural-color-900)', height: '2px' }} />

          <PrimaryButton onClick={logout}>{txt.Logout}</PrimaryButton>

        </div>
      </Drawer>
    </div>
  );
}
