export const playingRoleArr = [
    "Top-Order Batter",
    "Middle-Order Batter",
    "Wicket-Keeper Batter",
    "Wicket Keeper",
    "Bowler",
    "All-Rounder",
    "Lower-Order Batter",
    "Opening Batter",
    "None",
];

export const battingStyleArr = [
    "Left-Hand Bat",
    "Right-Hand Bat",
]

export const bowlingStyleArr = [
    "Right-Arm Fast",
    "Right-Arm Medium",
    "Left-Arm Fast",
    "Left-Arm Medium",
    "Slow Left-Arm Orthodox",
    "Slow Left-Arm Chinaman",
    "Right-Arm Off Break",
    "Right-Arm Leg break",
    "None",
]