import React from "react";
import { CustomSmallButton, CustomSmallRoundedButton } from "../CustomMUI/CustomSmallButton";

const MatchComponent = () => {
  return <div>
    <CustomSmallButton name="25 May" disabled={false}/>
  </div>;
};

export default MatchComponent;
